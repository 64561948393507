import { useEffect, useState } from "react";
// import { connectWallet,
//   getCurrentWalletConnected
//  } from "./utils/interact.js";
 import { createAlchemyWeb3 } from "@alch/alchemy-web3";
 import env from "react-dotenv";
 import { isMobile } from 'react-device-detect';
 
 require('dotenv').config();



 const apiKey = env.REACT_APP_TOKEN_APIKEY;

const Minter = (props) => {

  //State variables
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [url, setURL] = useState("");
  const [validWallet, setValidWallet] = useState("");

  var browser = "";
  if(isMobile){
    var browser = "TRUE";
  }
  else{
    var browser = "FALSE";
  }
  
  
  const web3 = createAlchemyWeb3(
    `https://eth-mainnet.alchemyapi.io/v2/${apiKey}`,
  );

  

  useEffect(async () => { 
    const {address, status} = await getCurrentWalletConnected();
    setWallet(address)
    setStatus(status); 

    //const walletString = await verifyWallet();
    //printRetString(walletString);
    addWalletListener();
  }, []);

  const connectWalletPressed = async () => { 
    const walletResponse = await connectWallet();
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);

    verifyWallet();

  };

  


  const connectWallet = async () => {
    if (window.ethereum) {
      try {
        const addressArray = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const obj = {
          status: <h1 data-w-id="aa3fb2eb-a63f-4694-1b36-4534ed4f5a82" class="heading mobile">Verifying...</h1>,
          address: addressArray[0],
        };
        
        return obj;
      } catch (err) {
        return {
          address: "",
          status: "😥 " + JSON.stringify(err.message),
        };
      }
    } else {
      return {
        address: "",
        status: (
          <span>
            <p>
              {" "}
              🦊{" "}
              <a target="_blank" style="color: white !important;" href={`https://metamask.io/download.html`}>
                You must install Metamask, a virtual Ethereum wallet, in your
                browser.
              </a>
            </p>
          </span>
        ),
      };
    }
  };

  const getCurrentWalletConnected = async () => {
    if (window.ethereum) {
      try {
        const addressArray = await window.ethereum.request({
          method: "eth_accounts",
        });
        if (addressArray.length > 0) {

         
          return {
            address: addressArray[0],
            status: <div class="redirect-section">
            <h1 data-w-id="aa3fb2eb-a63f-4694-1b36-4534ed4f5a82" class="heading mobile">Verify Ownership</h1>
            <p class="paragraph">This drop is exclusive to Habbo holders. To check your eligibility, click the button below and connect your MetaMask wallet. Please note that we are only verifying your wallet contains the required token(s) needed to be granted access to the shop. We do not store any wallet information or pass it through to the shop.</p>
            <button id="walletButton" onClick={connectWalletPressed}>
                {walletAddress.length > 0 ? (
                  "Connected: " +
                  String(walletAddress).substring(0, 6) +
                  "..." +
                  String(walletAddress).substring(38)
                ) : (
                  <span>Connect Wallet</span>
                )}
              </button>
          </div>,
          };
          
          

        } else {
          return {
            address: "",
            status: <div class="redirect-section">
            <h1 data-w-id="aa3fb2eb-a63f-4694-1b36-4534ed4f5a82" class="heading mobile">Verify Ownership</h1>
            <p class="paragraph">This drop is exclusive to Habbo holders. To check your eligibility, click the button below and connect your MetaMask wallet. Please note that we are only verifying your wallet contains the required token(s) needed to be granted access to the shop. We do not store any wallet information or pass it through to the shop.</p>
            <button id="walletButton" onClick={connectWalletPressed}>
                {walletAddress.length > 0 ? (
                  "Connected: " +
                  String(walletAddress).substring(0, 6) +
                  "..." +
                  String(walletAddress).substring(38)
                ) : (
                  <span>Connect Wallet</span>
                )}
              </button>
          </div>,
          };
        }
      } catch (err) {
        return {
          address: "",
          status: "😥 " + JSON.stringify(err.message),
        };
      }
    } else {
        if(isMobile){
          return {
            address: "",
            status: (
              <div class="redirect-section">
                <h1 data-w-id="aa3fb2eb-a63f-4694-1b36-4534ed4f5a82" class="heading mobile">Browsing on mobile?</h1>
                <p class="paragraph">Click the button below to open this webpage in the Metamask Browser, or copy and paste the URL of this webpage into the Metamask Browser</p>
                <a id="walletButton" href="https://metamask.app.link/dapp/verify.habbo.com/" >
                    Open in Metamask
                  </a>
              </div>
            ),
          };
        }
        else{
          return {
            address: "",
            status: (
              <div class="redirect-section">
                <h1 data-w-id="aa3fb2eb-a63f-4694-1b36-4534ed4f5a82" class="heading mobile">No Metamask extension found</h1>
                <p class="paragraph">Click the button below to download Metamask to supporting browsers</p>
                <a id="walletButton" href="https://metamask.io/download.html" >
                    Download Metamask
                  </a>
              </div>
            ),
          };
        }
      }
  };

  const onMintPressed = async () => { //TODO: implement
    
  };

  const verifyWallet = async () => {
    const {address, status} = await getCurrentWalletConnected();

    console.log(" Address: "+address.length);

    if ( address.length == 0 ){
      console.log("TRUE SHOW HOME");
      setWallet("");
          setStatus(
            <div class="redirect-section">
              <h1 data-w-id="aa3fb2eb-a63f-4694-1b36-4534ed4f5a82" class="heading mobile">Verify Ownership</h1>
              <p class="paragraph">This drop is exclusive to Habbo holders. To check your eligibility, click the button below and connect your MetaMask wallet. Please note that we are only verifying your wallet contains the required token(s) needed to be granted access to the shop. We do not store any wallet information or pass it through to the shop.</p>

              <button id="walletButton" onClick={connectWalletPressed}>
                {walletAddress.length > 0 ? (
                  "Connected: " +
                  String(walletAddress).substring(0, 6) +
                  "..." +
                  String(walletAddress).substring(38)
                ) : (
                  <span>Connect Wallet</span>
                )}
              </button>

            </div>
        );
        return false;
    }
    
    else{
    //const nfts = await web3.alchemy.getNfts({owner: address});
    const nfts = await web3.alchemy.getNfts({owner: address, contractAddresses: ["0x700a7c29daa50d5428424d5315af292f2a2c153a", "0x8a1bbef259b00ced668a8c69e50d92619c672176", "0x528b2d021b61e8bff0969836bac36e81df23ce80"]});

    var foundNFT = false;
    // var retString = "";
    // Print owner's wallet address:
    // retString = retString + "fetching NFTs for address: ";
    // retString = retString + address;
    // retString = retString + "\n...\n";

    // Print total NFT count returned in the response:
    // retString = retString + "number of NFTs found:";
    // retString = retString + nfts.totalCount;
    // retString = retString + "...";

    // Print contract address and tokenId for each NFT:
    for (const nft of nfts.ownedNfts) {
      // retString = retString + "===";
      // retString = retString + "contract address:";
      // retString = retString + nft.contract.address;
      // retString = retString + "token ID:";
      // retString = retString + nft.id.tokenId;
      if ( nft.contract.address == "0x528b2d021b61e8bff0969836bac36e81df23ce80" || nft.contract.address == "0x700a7c29daa50d5428424d5315af292f2a2c153a" || nft.contract.address == "0x8a1bbef259b00ced668a8c69e50d92619c672176"){
        
        //retString = retString + "FOUND NFT";
        foundNFT = true;
        
      }
      

      // const response = await web3.alchemy.getNftMetadata({
      //   contractAddress: "0xfb61bd914d4cd5509ecbd4b16a0f96349e52db3d",
      //   tokenId: nft.id.tokenId
      // });
      // var resString = JSON.stringify(response)
      // retString = retString + resString;
    }

    
    // retString = retString + "===";

    

    if(foundNFT == true){
      allowWallet();

    }
    else{
      denyWallet();

    }

    return foundNFT;
  }
  }

  function printRetString (walletString){
    setStatus(JSON.stringify(walletString));

  }

  function allowWallet(){
    setStatus(
      <div>
        <div class="redirect-section">
            <h1 data-w-id="aa3fb2eb-a63f-4694-1b36-4534ed4f5a82" class="heading mobile">Verified!</h1>
            <p class="paragraph">Congratulations! You're eligible to access the shop. Click the button below to enter.</p>
            
        </div>
        <div class="button-container"><a href="https://shop.habbo.com/?key=1356875734097" class="button-2 w-button">Proceed to Store</a></div>
        
      </div>
    );

  }
 
  function denyWallet(){
    setStatus(
      <div class="redirect-section">
            <h1 data-w-id="aa3fb2eb-a63f-4694-1b36-4534ed4f5a82" class="heading mobile">Denied!</h1>
            <p class="paragraph">This wallet is not eligible for this drop. Open MetaMask and connect to a different wallet.</p>
            
        </div>
    );
  }
  
  function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setWallet(accounts[0]);
          setStatus(<h1 data-w-id="aa3fb2eb-a63f-4694-1b36-4534ed4f5a82" class="heading mobile">Verifying...</h1>);
          
          verifyWallet();
          
          
        } else {
          setWallet("");
          setStatus(
            <div class="redirect-section">
              <h1 data-w-id="aa3fb2eb-a63f-4694-1b36-4534ed4f5a82" class="heading mobile">Verify Ownership</h1>
              <p class="paragraph">This drop is exclusive to Habbo holders. To check your eligibility, click the button below and connect your MetaMask wallet. Please note that we are only verifying your wallet contains the required token(s) needed to be granted access to the shop. We do not store any wallet information or pass it through to the shop.</p>

              <button id="walletButton" onClick={connectWalletPressed}>
                {walletAddress.length > 0 ? (
                  "Connected: " +
                  String(walletAddress).substring(0, 6) +
                  "..." +
                  String(walletAddress).substring(38)
                ) : (
                  <span>Connect Wallet</span>
                )}
              </button>

            </div>
        );
        }
      });
    } else {
      if(isMobile){
        setStatus(
          <div class="redirect-section">
                <h1 data-w-id="aa3fb2eb-a63f-4694-1b36-4534ed4f5a82" class="heading mobile">Browsing on mobile?</h1>
                <p class="paragraph">Click the button below to open this webpage in the Metamask Browser, or copy and paste this URL into the MetaMask Browser</p>
                <a id="walletButton" href="https://metamask.app.link/dapp/verify.habbo.com/" >
                    Open in Metamask
                  </a>
              </div>
        );
      }
      else{
        setStatus(
          <div class="redirect-section">
                <h1 data-w-id="aa3fb2eb-a63f-4694-1b36-4534ed4f5a82" class="heading mobile">No Metamask extension found</h1>
                <p class="paragraph">Click the button below to download Metamask to supporting browsers</p>
                <a id="walletButton" href="https://metamask.io/download.html" >
                    Download Metamask
                  </a>
              </div>
        );
      }
    }
  }

  return (
    <div className="Minter">
      

      
      <div id="status">
        {status}
      </div>

      

    </div>
  );
};

export default Minter;
